
import { Vue, Component } from 'vue-property-decorator'
import echarts from 'echarts'
import { dayFormat } from '@/utils/formatDate'

type Date = {
  operationTime: never;
  timingCounts: never;
  intelligenceCounts: never;
  manualCounts: never;
}
@Component({
  filters: {
    intelligenceUsage (n: any) {
      return n.intelligenceCounts === 0
        ? 0 : n.manualCounts + n.timingCounts !== 0
          ? Math.ceil(n.intelligenceCounts / (n.manualCounts + n.timingCounts + n.intelligenceCounts) * 100) : 100
    }
  }
})
export default class IrrigationStatistics extends Vue {
  monthList: any= []
  projectList= []
  areaList= []
  month: any= []
  dayTimeList= []
  timingList= []
  intelligenceList= []
  manualList= []
  statisticsForm= {
    projectAreaId: '',
    projectId: '',
    startTime: '',
    endTime: ''
  }

  private day30 = 1000 * 60 * 60 * 24 * 30
  private timeOptionRange: any =null

  pickerOptions= {

    onPick: (date: any) => {
      console.log('time: ', date.minDate)
      if (date.minDate && !date.maxDate) {
        this.timeOptionRange = date.minDate
      }
      if (date.maxDate) {
        this.timeOptionRange = null
      }
    },
    disabledDate: (time: {getTime: Function}) => {
      // 今天以后的时间禁用，选中的时间，30和后30天禁用
      return time.getTime() > Date.now() || (this.timeOptionRange && (time.getTime() > this.timeOptionRange.getTime() + this.day30 || time.getTime() < this.timeOptionRange.getTime() - this.day30))
    }

  }

  created () {
    this.month = [dayFormat((new Date().getTime()) - this.day30), dayFormat(Date.now())]
    this.dateMonth(this.month)

    this.getProjectList()
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectProjectByList).then((res) => {
      this.projectList = res.list || []
      if (res.list && res.list[0]) {
        this.statisticsForm.projectId = res.list[0].projectId
        this.send()
        this.projectChange()
      }
    })
  }

  areaChange () {
    this.$forceUpdate()
  }

  // 查询
  send () {
    if (this.statisticsForm.startTime) {
      console.log('this.statisticsForm: ', this.statisticsForm)
      this.$axios.get(this.$apis.irrigationStatistics.daily, this.statisticsForm)
        .then((res) => {
          this.dayTimeList = []
          this.timingList = []
          this.intelligenceList = []
          this.manualList = []
          res.forEach((v: Date) => {
            this.dayTimeList.push(v.operationTime)
            this.timingList.push(v.timingCounts)
            this.intelligenceList.push(v.intelligenceCounts)
            this.manualList.push(v.manualCounts)
          })
          this.$nextTick(() => {
            this.chart()
          })
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      this.$message({
        message: '时间段不能为空',
        type: 'warning'
      })
    }
  }

  // 月份选择
  dateMonth (data: Array<string>) {
    console.log('data: ', data)
    if (data) {
      this.statisticsForm.startTime = data[0]
      this.statisticsForm.endTime = data[1]
    } else {
      this.statisticsForm.startTime = ''
      this.statisticsForm.endTime = ''
    }
  }

  // 项目下拉选中
  projectChange () {
    if (this.statisticsForm.projectId) {
      this.statisticsForm.projectAreaId = ''
      this.areaList = []
      this.$axios.get(this.$apis.project.selectProjectAreaByList, {
        projectId: this.statisticsForm.projectId,
        isIdimArea: '1'
      })
        .then((res) => {
          this.areaList = res.list || []
        })
    } else {
      this.areaList = []
      this.statisticsForm.projectAreaId = ''
    }
  }

  // 折线图
  chart () {
    console.log(234)
    const option: any = {
      title: {
        text: '喷灌统计分析图（次）',
        left: '-5',
        textStyle: {
          color: '#fff',
          fontWeight: '500'
        }
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        top: '90px',
        left: '5',
        right: '25',
        bottom: '30',
        containLabel: true
      },
      backgroundColor: 'transparent',
      legend: {
        icon: 'rect',
        itemHeight: '2',
        orient: 'horizontal',
        align: 'left',
        top: '40px',
        left: '-5',
        textStyle: {
          fontSize: 12,
          color: '#8098BC'
        },
        data: ['智能计划', '定时计划']
      },
      color: ['#1AD1B0', '#1890FF'],
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#8098BC' // x轴横线颜色
          }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          margin: 10,
          textStyle: {
            fontSize: 12,
            color: '#8098BC'
          }
        },
        data: this.dayTimeList
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: 12,
            color: '#8098BC'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#8098BC'
          }
        }
      },
      series: [{
        name: '智能计划',
        type: 'line',
        symbol: 'circle',
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              width: 4
            }
          }
        },
        symbolSize: [8, 8],
        data: this.intelligenceList
      }, {
        name: '定时计划',
        type: 'line',
        symbol: 'circle',
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              width: 4
            }
          }
        },
        symbolSize: [8, 8],
        data: this.timingList
      }]
    }
    const myChart = echarts.init((document.getElementById('chart') as any))
    myChart.setOption(option)
    window.onresize = function () {
      myChart.resize()
    }
  }
}
